<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M38 20c0-9.941-8.059-18-18-18S2 10.059 2 20s8.059 18 18 18 18-8.059 18-18zm2 0c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20z"
    />
    <g transform="translate(17 7)">
      <circle cx="3.5" cy="24.5" r="2.5" />
      <path
        d="M2.966 19.923h1.068c.4-2.083.2-1.25 2.937-15.966C7.238 1.944 5.636 0 3.5 0S-.238 1.874.03 3.957c.2 1.319 3.136 17.285 2.936 15.966z"
      />
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
